<template>
	<div>
		<div class="table-page-search-wrapper" style="margin-bottom: 10px;padding: 20px;">
			<AdvanceTable :columns="columns" :data-source="list" :title="tableTitle" :loading="loading" rowKey="id"
				:scroll="{ x: 1300 }" size="middle" tableKey="view-text-table"
				@refresh="scorelist"
				:format-conditions="true" :pagination="{
			  current: queryParam.pageNum,
			  pageSize: queryParam.pageSize,
			  total: total,
			  showSizeChanger: true,
			  showLessItems: true,
			  showQuickJumper: true,
			  showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条，总计 ${total} 条`,
			  onChange: changeSize,
			  onShowSizeChange: onShowSizeChange,
			}">
			</AdvanceTable>
		</div>
	</div>
</template>

<script>
	import {
		scorelist
	} from '@/api/home/index'
	import AdvanceTable from '@/components/pt/table/AdvanceTable'//table组件引入
	import Cookies from "js-cookie";
	export default {
		components:{
			AdvanceTable
		},
		data() {
			return {
				//列表数据
				list: [],
				//table
				columns: [{
						title: '商品名称',
						dataIndex: 'productname',
					},
					{
						title: '花费积分',
						dataIndex: 'buyerbalance',
					},
					{
						title: '日期',
						dataIndex: 'insertdate',
					}
				],
				//table名
				tableTitle: '积分消费记录表单',
				loading: false,
				//总数量
				total: 0,
				//选中数组
				selectedRowKeys: [],
				userInfo:null,
				// 地址接口传参
				queryParam: {
					pageNum: 1,
					pageSize: 10,
				},
			}
		},
		
		created() {
			this.userInfo = JSON.parse(Cookies.get("userInfo2"))
			// console.log(this.userInfo)
			// this.scorelist()
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			//---------------我的消费积分记录接口-------------------------
			scorelist:function(){
				this.loading=true
				let data = {
					uid:this.userInfo.id,
					pageNum: this.queryParam.pageNum,
					pageSize: this.queryParam.pageSize,
				}
				console.log(data)
				scorelist(data).then(res=>{
					if(res.code=="200"){
						this.loading=false;
						this.list = res.data.content;
					}
				})
			},
			
			//---------------选择数量---------------------------
			changeSize: function(current, pageSize) {
				this.queryParam.pageNum = current
				this.queryParam.pageSize = pageSize
				// console.log("changeSize")
			},
			
			//--------------选择分页-----------------------------
			onShowSizeChange: function(current, pageSize) {
				this.queryParam.pageSize = pageSize
				// console.log("onShowSizeChange")
			},
		}
	}
</script>

<style lang="less" scoped>
	
</style>